<template>
  <div class="card-body mt-2 white">
    <div class="title text-end">
      <i class="bi bi-megaphone"
        ><span style="font-size: 14px; margin-left: 5px; color: black"
          >แจ้งเรื่อง</span
        ></i
      >
    </div>
    <div class="body-text">
      <div class="hide-scrollbar">
        <div class="appeal-title">
          <template v-for="title in Titles" :key="title">
            <div @click="setTitle(title.name)" class="appeal-item">
              <div class="appeal-image">
                <img :src="title.icon" class="appeal-image-logo" alt="" />
              </div>
              <div class="appeal-text">{{ title.name }}</div>
            </div>
          </template>
        </div>
      </div>
      <div class="scrollbar">
        <div class="scrollbar-st"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, inject } from "vue";
import { SET_APPEAL } from "@/store/modules/AppealModel";
export default defineComponent({
  name: "Appeal-Title",
  setup() {
    const store = inject("store");
    const router = inject("router");
    const Titles = store.getters.getTitle;

    const setTitle = (val) => {
      let appeal = {
        title: val,
        id_line: store.getters.getAccessToken.accesstoken,
      };
      store.dispatch(SET_APPEAL, appeal);
      router.push({ name: "Appeal" });
    };

    return {
      Titles,
      setTitle,
    };
  },
});
</script>
<style scoped>
.card-body {
  padding: 0;
}
.title {
  background-color: white;
  width: 100%;
  height: 50px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  padding: 15px 10px;
}
.title-text {
  font-size: 12px;
  margin-right: 5px;
}
.icon-logo {
  height: 27px;
  background-color: white;
  margin-left: 10px;
  border-radius: 50%;
  margin-top: -10px;
}
.body-text {
  height: 16rem;
  position: relative;
}
.hide-scrollbar {
  position: relative;
  height: 100%;
  overflow-y: hidden;
  scrollbar-width: none;
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}
.scrollbar {
  height: 0.25rem;
  border-radius: 0.0625rem;
  position: absolute;
  bottom: 0.3125rem;
  left: calc(50% - 0.75rem);
  width: 1.5rem;
  background: var(--color);
}
.scrollbar-st {
  background: #ee4d2d;
  width: 10.801px;
  transform: translateX(0px);
}
.appeal-title {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  height: 100%;
  width: 100%;
  padding-top: 1rem;
  padding-bottom: 0.125rem;
}

.appeal-item {
  width: 25%;
  padding: 0 0.0625rem;
  text-align: center;
  height: 7rem;
}
.appeal-image {
  position: relative;
}
.appeal-image-logo {
  vertical-align: bottom;
  display: inline-block;
  width: 50px;
  height: 50px;
  margin-bottom: 10px;
  border: 1.3px solid #dee2e6;
  border-radius: 15px;
}
</style>
