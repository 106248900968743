<template>
  <div class="row">
    <div class="col-12">
      <div class="card concard card-shadow">
        <Header></Header>
        <div class="text-start mn-profile">
          <span class="text-root mini-h"> แจ้งเรื่อง </span>
        </div>

        <Images :appeals="appeals"></Images>
        <Detail :appeals="appeals"></Detail>
        <Timeline :appeals="appeals"></Timeline>
        <Manage
          v-if="appeals.status === 'เสร็จสิ้น'"
          :appeals="appeals"
        ></Manage>
        <Rating
          v-if="appeals.status === 'เสร็จสิ้น'"
          :appeals="appeals"
        ></Rating>
        <div class="mb-47"></div>
        <div class="card-footer white">
          <div class="d-flex justify-center align-center">
            <button @click="back" class="button btn-back">ย้อนกลับ</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted, inject, ref } from "vue";
import Header from "@/components/Header";
import Images from "@/components/Detail/Images";
import Detail from "@/components/Detail/Detail";
import Timeline from "@/components/Detail/Timeline";
import Manage from "@/components/Detail/Manage";
import Rating from "@/components/Detail/Rating";
export default defineComponent({
  name: "Appeal-Detail",
  components: {
    Header,
    Images,
    Detail,
    Timeline,
    Manage,
    Rating,
  },
  setup() {
    const store = inject("store");
    const router = inject("router");
    const appeals = ref(store.getters.getAppealTo.detail);

    const back = () => {
      router.push({ name: "Dashboard" });
    };

    onMounted(() => {
      if (!store.getters.getAccessToken.accesstoken) {
        router.push({ name: "Home" });
      }
    });
    return {
      appeals,
      back,
    };
  },
});
</script>

<style scoped></style>
